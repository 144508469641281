import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Theme, Grid } from '@material-ui/core';

const Header = ({ siteTitle }) => {
    const classes = useStyles();
    return (
        <header className={classes.headerRoot} style={{}}>
            <Grid container alignContent="center" className={classes.titleContainer}>
                <h1 className={classes.title}>
                    <Link
                        to="/"
                        style={{
                            color: 'white',
                            textDecoration: 'none',
                        }}
                    >
                        {siteTitle}
                    </Link>
                </h1>
            </Grid>
        </header>
    );
};

const styles = (theme) =>
    createStyles({
        headerRoot: {
            background: theme.palette.grey[900],
            marginBottom: theme.spacing(2),
        },
        titleContainer: {
            margin: '0 auto',
            maxWidth: '90%',
            height: theme.spacing(10),
        },
        title: {
            margin: 0,
        },
    });
const useStyles = makeStyles(styles);

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: '',
};

export default Header;
