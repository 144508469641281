/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { makeStyles, createStyles, Theme, Grid } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import Background from '../images/body-bg.jpg';

import Header from './header';
import './layout.css';

const Layout = ({ children }) => {
    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <Grid className={classes.root}>
            <Header siteTitle={data.site.siteMetadata.title} />
            <Grid className={classes.content}>
                <main className={classes.main}>{children}</main>
            </Grid>
        </Grid>
    );
};

const styles = (theme) =>
    createStyles({
        root: {
            margin: '0 auto',
            maxHeight: `100vh`,
            padding: `1rem 2rem 1rem`,
            overflowY: 'auto',
            overflowX: 'hidden',
            backgroundImage: `url(${Background})`,
        },
        content: {
            height: `calc(100vh - ${theme.spacing(17)}px)`,
            paddingTop: 0,
        },
        main: {
            height: '100%',
        },
    });
const useStyles = makeStyles(styles);

export default Layout;
